import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Dienste.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import axios from 'axios';

const Dienste = () => {
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedCity, setSelectedCity] = useState('');
    const [quali, setQuali] = useState(localStorage.getItem('quali'));
    const [isAdmin, setIsAdmin] = useState(false);  // Admin-Status
    const navigate = useNavigate();
    const servicesRef = useRef(null);

    useEffect(() => {
        const fetchCities = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            // Überprüfe, ob der Token vorhanden und gültig ist
            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                return;
            }

            try {
                const response = await axios.get('https://85.215.137.109/Records/Cities', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setCities(response.data);
                if (response.data.length > 0) {
                    setSelectedCity(response.data[0]);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Fehlerbehandlung für abgelaufenen oder ungültigen Token
                    setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                    localStorage.removeItem('authToken'); // Optional: Entferne den abgelaufenen Token
                    localStorage.removeItem('tokenExpiration');
                } else {
                    setError('Fehler beim Laden der Städte.');
                }
            }
        };
        fetchCities();
    }, []);

    useEffect(() => {
        const fetchRecords = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://85.215.137.109/Records', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = response.data;
                setRecords(data);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('tokenExpiration');
                } else {
                    setError('Fehler beim Laden der Dienste.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchRecords();
    }, []);


    useEffect(() => {
        if (records && selectedCity) {
            const filtered = records.filter(record => record.ort === selectedCity);
            setFilteredRecords(filtered);
        }
    }, [records, selectedCity]);

    const handlePreBook = async (id) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('authToken');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        if (!userId || !token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
            alert('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
            navigate('/login');
            return;
        }

        try {
            const response = await axios.post(
                'https://85.215.137.109/Records/PreBookCheck',
                { serviceTimeId: id, userId, type: quali },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.data.AlreadyApplied) {
                setMessage(response.data.Message);
            } else {
                navigate(`/confirmation/${id}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setMessage('Sie sind bereits für diesen Dienst eingetragen.');
            } else if (error.response && error.response.status === 404) {
                setMessage('Dienst nicht gefunden.');
            } else {
                setMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
            }
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date.toLocaleDateString('de-DE'));
        if (servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleCityChange = (e) => {
        const city = e.target.value;
        setSelectedCity(city);
    };

    const getSeasonColor = (date) => {
        const month = date.getMonth();
        switch (month) {
            case 0:
                return 'winter-january';
            case 1:
                return 'winter-february';
            case 2:
                return 'spring-march';
            case 3:
                return 'spring-april';
            case 4:
                return 'spring-may';
            case 5:
                return 'summer-june';
            case 6:
                return 'summer-july';
            case 7:
                return 'summer-august';
            case 8:
                return 'autumn-september';
            case 9:
                return 'autumn-october';
            case 10:
                return 'autumn-november';
            case 11:
                return 'winter-december';
            default:
                return '';
        }
    };

    const getTileContent = ({ date, view }) => {
        if (view === 'month') {
            const recordsForDate = filteredRecords.filter(r => new Date(r.datum).toDateString() === date.toDateString());
            const seasonClass = getSeasonColor(date);

            if (recordsForDate.length > 0) {
                return (
                    <div className={`tile-content ${seasonClass}`}>
                        <FaRegCalendarAlt style={{ color: 'black', fontSize: '24px' }} />
                    </div>
                );
            }
            return <div className={`tile-content ${seasonClass}`}></div>;
        }
        return null;
    };

    const renderServiceTimes = (record) => {
        if (!record || !record.serviceTimes || record.serviceTimes.length === 0) return null;

        return record.serviceTimes.map((serviceTime) => (
            <div key={serviceTime.id} className="service-time">
                <p><strong>Uhrzeit:</strong> {serviceTime.startZeit} - {serviceTime.endZeit}</p>
                <p><strong>Dienstkleidung:</strong> {serviceTime.dienstkleidung}</p>
                {renderApplyButton(serviceTime)}
            </div>
        ));
    };

    const renderApplyButton = (serviceTime) => {
        const participant = serviceTime.participants[0];

        if (!participant) return null;

        let canApply = false;

        // Erlauben, dass Nutzer mit 'Sachkunde' sich auch für '34a' und 'Service' bewerben können
        if (quali === 'Sachkunde') {
            if (participant.takenSachkunde < participant.maxPlacesSachkunde ||
                participant.taken34a < participant.maxPlaces34a ||
                participant.takenService < participant.maxPlacesService) {
                canApply = true;
            }
        } else if (quali === '34a') {
            if (participant.taken34a < participant.maxPlaces34a ||
                participant.takenService < participant.maxPlacesService) {
                canApply = true;
            }
        } else if (quali === 'Service') {
            if (participant.takenService < participant.maxPlacesService) {
                canApply = true;
            }
        }

        if (canApply) {
            return (
                <button className="prebook-button" onClick={() => handlePreBook(serviceTime.id)}>
                    Bewerben
                </button>
            );
        }

        return <p className="error">Keine verfügbaren Plätze</p>;
    };


    const renderUpcomingServices = () => {
        const today = new Date();
        const upcomingServices = records
            .filter(record => new Date(record.datum) > today && new Date(record.datum).getMonth() === today.getMonth())
            .slice(0, 5);

        if (upcomingServices.length === 0) {
            return <p>Keine kommenden Dienste für diesen Monat.</p>;
        }

        return (
            <div className="upcoming-services">
                <h3>Kommende Dienste</h3>
                <div className="upcoming-services-container">
                    {upcomingServices.map((record) => (
                        <div key={record.id} className="upcoming-service-box">
                            <p><strong>Datum:</strong> {new Date(record.datum).toLocaleDateString('de-DE')}</p>
                            <p><strong>Stadt:</strong> {record.ort}</p>
                            <p><strong>Einsatzleitung:</strong> {record.einsatzleitung}</p>
                            {record.serviceTimes.map(serviceTime => (
                                <p key={serviceTime.id}><strong>Uhrzeit:</strong> {serviceTime.startZeit} - {serviceTime.endZeit}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderServicesForSelectedDate = () => {
        if (!selectedDate) return null;

        const servicesForDate = filteredRecords.filter(r => new Date(r.datum).toLocaleDateString('de-DE') === selectedDate);

        if (servicesForDate.length === 0) {
            return <p>Keine Dienste für das ausgewählte Datum.</p>;
        }

        return (
            <ul className="services-list" ref={servicesRef}>
                {servicesForDate.map((record) => (
                    <li key={record.id} className="service-item">
                        <div className="service-info">
                            <p><strong>Dienst:</strong> {record.dienstname}</p>
                            <p><strong>Ort:</strong> {record.ort}</p>
                            {renderServiceTimes(record)}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    {error.includes('Sitzung') && (
                        <button className="btn btn-primary mt-2" onClick={() => navigate('/login')}>Zum Login</button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="dienste">
            {message && <div className="alert alert-info">{message}</div>}
            <div className="city-selector">
                <label>Stadt wählen:</label>
                <select value={selectedCity} onChange={handleCityChange}>
                    {cities.map(city => (
                        <option key={city} value={city}>{city}</option>
                    ))}
                </select>
            </div>
            <Calendar
                onClickDay={handleDateClick}
                tileContent={getTileContent}
                className="custom-calendar"
                minDetail="month"
                maxDetail="month"
                showDoubleView={false}
            />
            <div className="services">
                {selectedDate && <h3>Dienste für {selectedDate}</h3>}
                {renderServicesForSelectedDate()}
            </div>
            <div className="upcoming-services-section">
                {renderUpcomingServices()}
            </div>

        </div>
    );
};

export default Dienste;
