import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus, FaMinus, FaTrash, FaEdit } from 'react-icons/fa'; // FaEdit importieren
import './UserList.css';
import axios from 'axios';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [expandedUserId, setExpandedUserId] = useState(null); // State für ausgeklappte Dienste
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]); // Dienste aus dem Admin/Records-Endpunkt
    const [selectedRecord, setSelectedRecord] = useState(null); // Ausgewählter Dienst
    const [selectedServiceTime, setSelectedServiceTime] = useState(null); // Ausgewählte Servicezeit
    const [assignUserId, setAssignUserId] = useState(null); // ID des Benutzers, der zugewiesen werden soll

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const userResponse = await fetch('https://85.215.137.109/Users/list', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!userResponse.ok) {
                    throw new Error('Fehler beim Abrufen der Benutzerdaten.');
                }

                const userData = await userResponse.json();
                setUsers(userData);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
                setError('Fehler beim Abrufen der Benutzerdaten.');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const toggleExpand = (userId) => {
        setExpandedUserId(prevId => (prevId === userId ? null : userId));
    };

    const fetchRecords = async () => {
        const token = localStorage.getItem('authToken');

        try {
            const response = await axios.get('https://85.215.137.109/Records/Admin/Records', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            setRecords(response.data);

        } catch (error) {
            console.error('Fehler beim Abrufen der Dienste:', error);
        }
    };

    const handleAssignToService = async (userId) => {
        setAssignUserId(userId);
        await fetchRecords(); // Lade die Liste der Dienste
    };

    const handleSelectRecord = (recordId) => {
        const record = records.find(r => r.id === recordId);
        setSelectedRecord(record);
        setSelectedServiceTime(null); // Setze die ausgewählte Servicezeit zurück
    };

    const handleSelectServiceTime = (serviceTimeId) => {
        const serviceTime = selectedRecord.serviceTimes.find(st => st.id === serviceTimeId);
        setSelectedServiceTime(serviceTime);
    };

    const handleConfirmAssignment = async () => {
        if (!assignUserId || !selectedServiceTime) return;

        try {
            const token = localStorage.getItem('authToken');

            const selectedUser = users.find(user => user.id === assignUserId);

            if (!selectedUser) {
                console.error('Benutzer nicht gefunden.');
                return;
            }

            const response = await axios.post(
                'https://85.215.137.109/ServiceRequests/Admin/RequestBooking',
                {
                    userId: selectedUser.id,
                    serviceTimeId: selectedServiceTime.id,
                    quali: selectedUser.quali,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                console.log('Request successfully created.');
            } else {
                console.error('Fehler beim Erstellen der Anfrage:', response.data);
            }

            setAssignUserId(null);
            setSelectedRecord(null);
            setSelectedServiceTime(null);
        } catch (error) {
            console.error('Fehler beim Zuweisen des Mitarbeiters:', error.response?.data || error.message);
        }
    };

    const handleAddUser = () => {
        navigate('/add-user');
    };

    const handleEditUser = (userId) => {
        navigate(`/edit-user/${userId}`); // Leitet zur EditUser-Seite weiter
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('Möchten Sie diesen Benutzer wirklich löschen?')) {
            try {
                const token = localStorage.getItem('authToken');
                await axios.delete(`https://85.215.137.109/Users/Admin/removeUser/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                // Entferne den Benutzer aus der UI
                setUsers(users.filter(user => user.id !== userId));

                alert('Benutzer erfolgreich gelöscht.');
            } catch (error) {
                console.error('Fehler beim Löschen des Benutzers:', error);
                alert('Fehler beim Löschen des Benutzers.');
            }
        }
    };

    const sortedUsers = [...users].sort((a, b) => {
        const compareA = a[sortField]?.toString().toLowerCase() || '';
        const compareB = b[sortField]?.toString().toLowerCase() || '';

        if (compareA < compareB) return sortOrder === 'asc' ? -1 : 1;
        if (compareA > compareB) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const filteredUsers = sortedUsers.filter(user =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return <div className="loading">Laden...</div>;
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    {error.includes('Sitzung') && (
                        <button className="btn btn-primary mt-2" onClick={() => navigate('/login')}>Zum Login</button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="user-list-container">
            <div className="header-container">
                <h2 className="header-title">Benutzerliste</h2>
                <FaPlus
                    onClick={handleAddUser}
                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'green', fontSize: '24px' }}
                    title="Benutzer hinzufügen"
                />
            </div>

            <div className="search-sort-container">
                <input
                    type="text"
                    placeholder="Nach Name oder E-Mail suchen..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <button onClick={() => handleSort('id')} className="sort-button">
                    Nach ID sortieren
                </button>
                <button onClick={() => handleSort('quali')} className="sort-button">
                    Nach Qualifikation sortieren
                </button>
            </div>

            <table className="user-table">
                <thead>
                    <tr>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>E-Mail</th>
                        <th>Qualifikation</th>
                        <th>Dienste</th>
                        <th>E-PIN</th>
                        <th>BewacherID</th>
                        <th>Aktionen</th> {/* Spalte für Aktionen (Bearbeiten, Löschen) */}
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.quali}</td>
                                <td>
                                    {user.dienste && user.dienste.length > 0 ? (
                                        expandedUserId === user.id ? (
                                            <FaChevronUp
                                                onClick={() => toggleExpand(user.id)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        ) : (
                                            <FaChevronDown
                                                onClick={() => toggleExpand(user.id)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )
                                    ) : (
                                        <>
                                            Keine Dienste
                                            {assignUserId === user.id ? (
                                                <FaMinus
                                                    onClick={() => setAssignUserId(null)}
                                                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                                                />
                                            ) : (
                                                <FaPlus
                                                    onClick={() => handleAssignToService(user.id)}
                                                    style={{ cursor: 'pointer', marginLeft: '10px', color: 'green' }}
                                                />
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>{user.ePin}</td>
                                <td>{user.bewacherId}</td>
                                <td>
                                    <FaEdit
                                        onClick={() => handleEditUser(user.id)} // Bearbeiten-Button hinzugefügt
                                        style={{ cursor: 'pointer', marginRight: '10px', color: 'blue' }}
                                        title="Benutzer bearbeiten"
                                    />
                                    <FaTrash
                                        onClick={() => handleDeleteUser(user.id)}
                                        style={{ cursor: 'pointer', color: 'red' }}
                                        title="Benutzer löschen"
                                    />
                                </td>
                            </tr>
                            {expandedUserId === user.id && (
                                <tr>
                                    <td colSpan="8">
                                        <div className="dienste-container">
                                            {user.dienste.map((dienst, idx) => (
                                                <div key={idx} className="dienst-item">
                                                    <span>
                                                        {dienst.dienstname} am {new Date(dienst.datum).toLocaleDateString('de-DE')} ({dienst.startZeit} - {dienst.endZeit}) in {dienst.ort}
                                                    </span>
                                                    {assignUserId === user.id ? (
                                                        <FaMinus
                                                            onClick={() => setAssignUserId(null)}
                                                            style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                                                        />
                                                    ) : (
                                                        <FaPlus
                                                            onClick={() => handleAssignToService(user.id)}
                                                            style={{ cursor: 'pointer', marginLeft: '10px', color: 'green' }}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>

            {assignUserId && (
                <div className="assignment-modal">
                    <h3>Dienst auswählen</h3>
                    <div className="record-selection">
                        {records.map(record => (
                            <div
                                key={record.id}
                                className={`record-item ${selectedRecord && selectedRecord.id === record.id ? 'selected' : ''}`}
                                onClick={() => handleSelectRecord(record.id)}
                            >
                                {record.dienstname} am {new Date(record.datum).toLocaleDateString('de-DE')} in {record.ort}

                            </div>
                        ))}
                    </div>

                    {selectedRecord && (
                        <>
                            <h4>Servicezeit auswählen</h4>
                            <div className="service-time-selection">
                                {selectedRecord.serviceTimes.map(st => (
                                    <div
                                        key={st.id}
                                        className={`service-time-item ${selectedServiceTime && selectedServiceTime.id === st.id ? 'selected' : ''}`}
                                        onClick={() => handleSelectServiceTime(st.id)}
                                    >
                                        {st.startZeit} - {st.endZeit}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {selectedServiceTime && (
                        <button onClick={handleConfirmAssignment} className="assign-button">
                            Mitarbeiter zuweisen
                        </button>
                    )}

                    <button onClick={() => setAssignUserId(null)} className="cancel-button">
                        Abbrechen
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserList;
