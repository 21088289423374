import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './AdminServiceRequests.css';

const AdminServiceRequests = () => {
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedQuali, setSelectedQuali] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRequests = async () => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch('https://85.215.137.109/ServiceRequests/Admin/ServiceRequests', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Anfrage fehlgeschlagen!');
                }

                const data = await response.json();
                setRequests(data);

                // Städte extrahieren
                const cityList = [...new Set(data.map(req => req.city))];
                setCities(cityList);
                setFilteredRequests(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    const handleApprove = async (serviceRequestId, quali, email) => {
        if (window.confirm(`Möchten Sie die Anfrage von ${email} wirklich genehmigen?`)) {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(`https://85.215.137.109/ServiceRequests/Admin/ServiceRequests/Approve/${serviceRequestId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ quali }), // Die ausgewählte Qualifikation senden
                });

                if (!response.ok) {
                    throw new Error('Genehmigung fehlgeschlagen!');
                }

                setRequests((prevRequests) => prevRequests.filter((request) => request.id !== serviceRequestId));
                window.alert(`Bestätigungsmail wurde an ${email} verschickt.`);
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const handleReject = async (serviceRequestId, email) => {
        if (window.confirm(`Möchten Sie die Anfrage von ${email} wirklich ablehnen?`)) {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(`https://85.215.137.109/ServiceRequests/Admin/ServiceRequests/Reject/${serviceRequestId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Ablehnung fehlgeschlagen!');
                }

                setRequests((prevRequests) => prevRequests.filter((request) => request.id !== serviceRequestId));
                window.alert(`Die Anfrage von ${email} wurde abgelehnt.`);
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const handleQualiChange = (requestId, quali) => {
        setSelectedQuali(prevState => ({ ...prevState, [requestId]: quali }));
    };

    useEffect(() => {
        let filtered = requests;

        if (selectedCity) {
            filtered = filtered.filter(req => req.city === selectedCity);
        }

        if (searchTerm) {
            filtered = filtered.filter(req =>
                req.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                `${req.firstName} ${req.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
                req.recordDescription.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredRequests(filtered);
    }, [selectedCity, searchTerm, requests]);

    const getQualiOptions = (userQuali) => {
        switch (userQuali) {
            case 'Sachkunde':
                return ['Service', '34a', 'Sachkunde'];
            case '34a':
                return ['Service', '34a'];
            case 'Service':
                return ['Service'];
            default:
                return [];
        }
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    <button className="btn btn-primary mt-2" onClick={() => navigate('/home')}>Schade :(</button>
                </div>
            </div>
        );
    }

    return (
        <div className="admin-service-requests">
            <div className="back-arrow" onClick={() => navigate('/home')}>
                <FaArrowLeft size={24} color="black" />
            </div>

            <h1>Serviceanfragen</h1>

            <div className="filters">
                <select
                    className="custom-select"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                >
                    <option value="">Alle Städte</option>
                    {cities.map((city, index) => (
                        <option key={index} value={city}>{city}</option>
                    ))}
                </select>

                <input
                    type="text"
                    placeholder="Suche nach Benutzer oder Dienstbeschreibung"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="custom-input"
                />
            </div>

            {filteredRequests.length === 0 ? (
                <p>Keine offenen Anfragen.</p>
            ) : (
                <div className="requests-grouped">
                    {filteredRequests
                        .reduce((acc, curr) => {
                            const record = acc.find(r => r.recordName === curr.recordName);
                            if (record) {
                                record.requests.push(curr);
                            } else {
                                acc.push({ recordName: curr.recordName, requests: [curr] });
                            }
                            return acc;
                        }, [])
                        .map((group, index) => (
                            <div key={index} className="record-group">
                                <h3>{group.recordName}</h3>
                                <ul className="requests-list">
                                    {group.requests.map((request) => (
                                        <li key={request.id} className="request-item">
                                            <p><strong>Benutzer:</strong> {request.firstName} {request.lastName} ({request.email})</p>
                                            <p><strong>Datum:</strong> {new Date(request.requestDate).toLocaleString()}</p>
                                            <p><strong>Qualifikation:</strong> {request.quali}</p>

                                            <select
                                                value={selectedQuali[request.id] || ''}
                                                onChange={(e) => handleQualiChange(request.id, e.target.value)}
                                                className="custom-select"
                                            >
                                                <option value="">Wähle eine Qualifikation</option>
                                                {getQualiOptions(request.quali).map((option, idx) => (
                                                    <option key={idx} value={option}>{option}</option>
                                                ))}
                                            </select>

                                            <button
                                                className="custom-button first"
                                                onClick={() => handleApprove(request.id, selectedQuali[request.id], request.email)}
                                                disabled={!selectedQuali[request.id]} // Disable the button if no qualification is selected
                                            >
                                                Genehmigen
                                            </button>
                                            <button
                                                className="custom-button last"
                                                onClick={() => handleReject(request.id, request.email)}
                                            >
                                                Ablehnen
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default AdminServiceRequests;
