import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importiere FontAwesome
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Importiere die Icons
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Ladezustand hinzuf�gen
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true); // Ladezustand auf true setzen

        try {
            const response = await fetch('https://85.215.137.109/Auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Login fehlgeschlagen!');
            }

            const data = await response.json();
            const decodedToken = jwtDecode(data.token);

            localStorage.setItem('authToken', data.token);
            localStorage.setItem('tokenExpiration', data.expiration);
            localStorage.setItem('usermail', email);
            localStorage.setItem('isAdmin', decodedToken.role === 'Admin');
            localStorage.setItem('userId', data.userId);
            localStorage.setItem('quali', data.quali);

            navigate('/home');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false); // Ladezustand auf false setzen, egal ob Erfolg oder Fehler
        }
    };

    return (
        <div className="login-container">
            <div className="login-header">
                <img src="/pics/powerLogo.png" alt="Power Logo" className="logo" />
            </div>
            <div className="login-content">
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="email">E-Mail</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Passwort</label>
                        <div className="password-container">
                            <input
                                type={showPassword ? 'text' : 'password'} // Passwort anzeigen oder verstecken
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <span
                                className="password-toggle-icon"
                                onClick={() => setShowPassword(!showPassword)} // Passwort-Sichtbarkeit umschalten
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /> {/* Icon f�r Auge */}
                            </span>
                        </div>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" disabled={loading}> {/* Button deaktivieren, wenn loading true ist */}
                        {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Bootstrap Spinner
                        ) : (
                            'Abschicken'
                        )}
                    </button>
                </form>
            </div>
            <div className="login-footer">
                <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
            </div>
        </div>
    );
};

export default Login;
