import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddUser.css';
import { FaArrowLeft } from 'react-icons/fa'; // Importiere das Icon
import axios from 'axios';

const AddUser = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        isAdmin: false,
        firstName: '',
        lastName: '',
        quali: '',
        bewacherId: '',
        ePin: ''
    });

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Standardwerte, wenn EPin, BewacherId oder Email nicht angegeben sind
        const defaultEmail = formData.email ? formData.email : 'default@example.com';
        const defaultBewacherId = formData.bewacherId ? formData.bewacherId : 'Nicht verfügbar';
        const defaultEPin = formData.ePin ? formData.ePin : 'Nicht verfügbar';

        try {
            const token = localStorage.getItem('authToken');

            // Erstelle das JSON-Objekt mit den Benutzerdaten
            const userData = {
                email: defaultEmail,
                isAdmin: formData.isAdmin,
                firstName: formData.firstName,
                lastName: formData.lastName,
                quali: formData.quali,
                bewacherId: defaultBewacherId,
                ePin: defaultEPin
            };

            console.log('JSON-Objekt, das gesendet wird:', JSON.stringify(userData)); // Debugging: zeigt das JSON an

            // Führe den API-Aufruf aus
            const response = await axios.post(
                'https://85.215.137.109/Users/Admin/AddUser', // API-Endpunkt zum Hinzufügen des Benutzers
                userData, // Das JSON-Objekt senden
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                navigate('/home/showusers'); // Zur Benutzerliste weiterleiten nach dem Hinzufügen
            }
        } catch (error) {
            setError('Fehler beim Erstellen des Benutzers.');
        } finally {
            setLoading(false);
        }
    };

    // Der return-Block sollte hier sein, nicht in der handleSubmit-Funktion
    return (
        <div className="add-user-container">
            {/* Pfeil, der zur Benutzerliste zurückführt */}
            <button className="back-button" onClick={() => navigate('/home/showusers')}>
                <FaArrowLeft /> Zurück zur Benutzerliste
            </button>

            <h2>Neuen Benutzer anlegen</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="add-user-form">
                <div className="form-group">
                    <label htmlFor="firstName">Vorname:</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Nachname:</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">E-Mail (Optional):</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Standard: default@example.com"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="quali">Qualifikation:</label>
                    <select
                        id="quali"
                        name="quali"
                        value={formData.quali}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Bitte wählen</option>
                        <option value="Service">Service</option>
                        <option value="34a">34a</option>
                        <option value="Sachkunde">Sachkunde</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="bewacherId">BewacherID (Optional):</label>
                    <input
                        type="text"
                        id="bewacherId"
                        name="bewacherId"
                        value={formData.bewacherId}
                        onChange={handleChange}
                        placeholder="Standard: Nicht verfügbar"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="ePin">E-PIN (Optional):</label>
                    <input
                        type="text"
                        id="ePin"
                        name="ePin"
                        value={formData.ePin}
                        onChange={handleChange}
                        placeholder="Standard: Nicht verfügbar"
                    />
                </div>
                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            name="isAdmin"
                            checked={formData.isAdmin}
                            onChange={handleChange}
                        />
                        Admin-Benutzer
                    </label>
                </div>
                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Wird erstellt...' : 'Benutzer anlegen'}
                </button>
            </form>
        </div>
    );
};

export default AddUser;
