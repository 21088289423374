import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MitarbeiterBereich = () => {
    const [welcomeMessage, setWelcomeMessage] = useState(true);
    const [userData, setUserData] = useState({ firstName: '', lastName: '', dienste: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [changeStatus, setChangeStatus] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            // Überprüfen, ob der Benutzer angemeldet ist und das Token noch gültig ist
            if (!userId || !token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const userResponse = await fetch(`https://85.215.137.109/Users/${userId}/services`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                // Überprüfen, ob die Antwort den Status 404 hat
                if (userResponse.status === 404) {
                    // Wenn die API "not found" zurückgibt, zeigen wir eine passende Nachricht an
                    setError('Keine Dienste gefunden.');
                    setUserData({
                        firstName: '', // Optionale Werte, falls nötig
                        lastName: '',
                        dienste: [],
                    });
                } else if (userResponse.ok) {
                    // Wenn die Antwort erfolgreich ist (Status 200), verarbeiten wir die Daten
                    const userData = await userResponse.json();
                    setUserData({
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        dienste: userData.dienste || [], // Leere Liste, wenn keine Dienste vorhanden sind
                    });
                } else {
                    // Für andere Fehler werfen wir einen allgemeinen Fehler
                    throw new Error('Fehler beim Abrufen der Dienste');
                }

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();

        const timer = setTimeout(() => {
            setWelcomeMessage(false);
        }, 7000);

        return () => clearTimeout(timer);
    }, [navigate]);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        const passwordRequirements = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

        if (!passwordRequirements.test(newPassword)) {
            setPasswordError('Das neue Passwort muss mindestens 6 Zeichen lang sein, mindestens einen Buchstaben und eine Zahl enthalten.');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setPasswordError('Die neuen Passwörter stimmen nicht überein.');
            return;
        }

        const token = localStorage.getItem('authToken');

        try {
            const response = await fetch('https://85.215.137.109/Auth/change-password', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    oldPassword,
                    newPassword,
                }),
            });

            if (response.ok) {
                setChangeStatus('Änderung erfolgreich');
            } else {
                setChangeStatus('Änderung fehlgeschlagen');
            }
        } catch (err) {
            setChangeStatus('Änderung fehlgeschlagen');
        } finally {
            setShowPasswordChange(false);

            setTimeout(() => {
                setChangeStatus('');
            }, 5000);
        }
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div>
            {/* Error anzeigen, aber nicht das restliche UI blockieren */}
            {error && (
                <div className="alert alert-danger">
                    {error}
                    {error.includes('Sitzung') && (
                        <button className="btn btn-primary mt-2" onClick={() => navigate('/login')}>
                            Zum Login
                        </button>
                    )}
                </div>
            )}

            {welcomeMessage && (
                <div className="alert alert-info">
                    Herzlich willkommen {userData.firstName}, {userData.lastName}!
                </div>
            )}

            {/* Liste der Dienste */}
            <h2 className="mt-4">Ihre eingeschriebenen Dienste:</h2>
            <ul className="list-group">
                {userData.dienste.length > 0 ? (
                    userData.dienste.map((dienst, index) => (
                        <li key={index} className="list-group-item">
                            <strong>Beschreibung:</strong> {dienst.description}<br />
                            <strong>Zeit:</strong> {dienst.startZeit} bis {dienst.endZeit}<br />
                            <strong>Datum:</strong> {new Date(dienst.datum).toLocaleDateString('de-DE')}<br />
                            <strong>Ort:</strong> {dienst.ort}<br />
                            <strong>Adresse:</strong> {dienst.adresse}<br />
                            <strong>Einsatzleitung:</strong> {dienst.einsatzleitung}<br />
                            <strong>Disponent:</strong> {dienst.disponent}<br />
                            <strong>Dienstkleidung:</strong> {dienst.dienstkleidung}<br />
                            <strong>Qualifikation:</strong> {dienst.quali}
                        </li>
                    ))
                ) : (
                    <li className="list-group-item">Sie sind in keine Dienste eingeschrieben.</li>
                )}
            </ul>

            {/* Button für Passwort ändern */}
            <div className="mt-4">
                <button className="btn btn-secondary" onClick={() => setShowPasswordChange(!showPasswordChange)}>
                    Passwort ändern
                </button>
            </div>

            {/* Passwort ändern Formular */}
            {showPasswordChange && (
                <div className="mt-4">
                    <h3>Passwort ändern</h3>
                    <form onSubmit={handlePasswordChange}>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Altes Passwort:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Neues Passwort:</label>
                            <input
                                type="password"
                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            {passwordError && (
                                <div className="invalid-feedback">
                                    {passwordError}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Neues Passwort bestätigen:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary mt-2">
                            Passwort ändern
                        </button>
                    </form>
                </div>
            )}

            {/* Änderung Status */}
            {changeStatus && (
                <div className={`alert mt-4 ${changeStatus === 'Änderung erfolgreich' ? 'alert-success' : 'alert-danger'}`}>
                    {changeStatus}
                </div>
            )}
        </div>
    );
};

export default MitarbeiterBereich;
