import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const ConfirmationPage = () => {
    const { id } = useParams(); // Das ist die ServiceTimeId
    const navigate = useNavigate();
    const [record, setRecord] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const token = localStorage.getItem('authToken');
    const timerRef = useRef(null);
    const [manualCancel, setManualCancel] = useState(false);

    const cancelBooking = useCallback(async () => {
        try {
            await fetch('https://85.215.137.109/Records/CancelBooking', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ServiceTimeId: id, UserId: userId }), // ServiceTimeId und UserId senden
            });
            setIsCanceled(true);
            if (timerRef.current) {
                clearInterval(timerRef.current); // Stop the timer
                timerRef.current = null;
            }
            setRemainingTime(0);
        } catch (error) {
            setError('Fehler bei der Stornierung der Buchung.');
        }
    }, [id, token, userId]);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://85.215.137.109/ServiceRequests/ServiceTime/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Buchungsdaten.');
                }

                const data = await response.json();
                setRecord(data);

                // Set initial remaining time for demonstration
                const expirationTime = new Date().getTime() + 1 * 60 * 1000; // 1 minute from now
                setRemainingTime(expirationTime - new Date().getTime());

                // Timer to update remaining time
                if (!timerRef.current) {
                    timerRef.current = setInterval(() => {
                        setRemainingTime(prevTime => {
                            if (prevTime <= 0) {
                                clearInterval(timerRef.current);
                                timerRef.current = null;
                                if (!isConfirmed && !manualCancel) {
                                    cancelBooking(); // Automatically cancel when time runs out
                                }
                                return 0;
                            }
                            return prevTime - 1000;
                        });
                    }, 1000);
                }
            } catch (error) {
                setError('Fehler beim Laden der Buchungsdaten.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const userId = localStorage.getItem('userId');
        setUserId(userId);

        // Clean up timer on component unmount
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [id, isConfirmed, manualCancel, cancelBooking]);

    const confirmBooking = async () => {
        try {
            const data = {
                ServiceTimeId: id,
                UserId: userId,
                Quali: localStorage.getItem('quali')

            };

            const response = await fetch('https://85.215.137.109/ServiceRequests/Admin/RequestBooking', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setIsConfirmed(true);
                if (timerRef.current) {
                    clearInterval(timerRef.current); // Stop the timer
                    timerRef.current = null;
                }
                setRemainingTime(0);
            } else {
                setError('Fehler bei der Bestätigung der Buchung oder Sie haben sich bereits für den Dienst beworben.');
            }
        } catch (error) {
            setError('Fehler bei der Bestätigung der Buchung oder Sie haben sich bereits für den Dienst beworben.');
        }
    };

    const handleManualCancel = () => {
        setManualCancel(true);
        cancelBooking();
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${minutes}m ${seconds}s`;
    };

    if (loading) {
        return <div className="text-center mt-5">Lade...</div>;
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    <button className="btn btn-primary mt-2" onClick={() => navigate('/home/dienste')}>Zu den Diensten</button>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{record?.beschreibung}</h5>
                    <p className="card-text"><strong>Einsatzleitung:</strong> {record?.einsatzleiter}</p>
                    <p className="card-text"><strong>Disponent:</strong> {record?.disponent}</p>
                    <p className="card-text"><strong>Datum:</strong> {formatDate(record?.datum)}</p>
                    <p className="card-text"><strong>Zeit:</strong> {record?.startZeit} - {record?.endZeit}</p>
                    <p className="card-text"><strong>Ort:</strong> {record?.ort}</p>
                    <p className="card-text"><strong>Adresse:</strong> {record?.adresse}</p>

                    {error && <div className="alert alert-danger">{error}</div>}

                    {isConfirmed && (
                        <div className="alert alert-success">
                            Danke, Ihre Anmeldung wurde erfolgreich eingereicht!
                            <button className="btn btn-secondary mt-2" onClick={() => navigate('/home/dienste')}>Zurück zu den Diensten</button>
                        </div>
                    )}

                    {isCanceled && (
                        <div className={`alert ${manualCancel ? 'alert-warning' : 'alert-danger'}`}>
                            {manualCancel
                                ? 'Die Buchung wurde manuell storniert.'
                                : 'Die Buchung wurde automatisch storniert, da die Zeit abgelaufen ist.'
                            }
                            <button className="btn btn-secondary mt-2" onClick={() => navigate('/home/dienste')}>Zurück zu den Diensten</button>
                        </div>
                    )}

                    {!isConfirmed && !isCanceled && (
                        <>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-success" onClick={confirmBooking}>
                                    Bewerben
                                </button>
                                <button className="btn btn-danger" onClick={handleManualCancel}>Stornieren</button>
                            </div>
                            <div className="text-right mt-2">
                                <span className="text-muted">Verbleibende Zeit: {formatTime(remainingTime)}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPage;
