import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const EditRecordManager = () => {
    const [einsatzleitung, setEinsatzleitung] = useState('');
    const [disponent, setDisponent] = useState('');
    const [dienstname, setDienstname] = useState('');
    const [description, setDescription] = useState('');
    const [datum, setDatum] = useState('');
    const [city, setCity] = useState('');
    const [adresse, setAdresse] = useState('');
    const [shifts, setShifts] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchRecord = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setPopupMessage('Ihre Sitzung ist abgelaufen. Sie werden zum Login weitergeleitet.');
                setShowPopup(true);
                return;
            }

            try {
                const response = await fetch(`https://85.215.137.109/Records/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen des Dienstes');
                }

                const record = await response.json();
                setEinsatzleitung(record.einsatzleitung);
                setDisponent(record.disponent);
                setDienstname(record.dienstname);
                setDescription(record.description);
                setDatum(record.datum.split('T')[0]);
                setCity(record.ort);
                setAdresse(record.adresse);
                setShifts(record.shifts.map(shift => ({
                    ...shift,
                    participants: shift.participants || [{
                        maxPlacesService: 0,
                        maxPlaces34a: 0,
                        maxPlacesSachkunde: 0
                    }]
                })));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecord();
    }, [id]);

    const handleAddShift = () => {
        setShifts([
            ...shifts,
            {
                startZeit: '',
                endZeit: '',
                dienstkleidung: 'Anzug',
                participants: [{
                    maxPlacesService: 0,
                    maxPlaces34a: 0,
                    maxPlacesSachkunde: 0
                }]
            }
        ]);
    };

    const handleRemoveShift = (index) => {
        const updatedShifts = shifts.filter((_, i) => i !== index);
        setShifts(updatedShifts);
    };

    const handleShiftChange = (index, field, value) => {
        const updatedShifts = shifts.map((shift, i) => {
            if (i === index) {
                if (['maxPlacesService', 'maxPlaces34a', 'maxPlacesSachkunde'].includes(field)) {
                    return {
                        ...shift,
                        participants: shift.participants.map((participant, pIndex) => {
                            if (pIndex === 0) {
                                return {
                                    ...participant,
                                    [field]: Number(value)
                                };
                            }
                            return participant;
                        })
                    };
                } else {
                    return {
                        ...shift,
                        [field]: value
                    };
                }
            }
            return shift;
        });
        setShifts(updatedShifts);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const token = localStorage.getItem('authToken');

        const updatedRecord = {
            einsatzleitung,
            disponent,
            description,
            datum,
            ort: city,
            dienstname,
            adresse,
            Shifts: shifts.map(shift => ({
                ZeitStart: shift.startZeit,
                ZeitEnd: shift.endZeit,
                Dienstkleidung: shift.dienstkleidung,
                MaxPlacesService: shift.participants[0]?.maxPlacesService,
                MaxPlaces34a: shift.participants[0]?.maxPlaces34a,
                MaxPlacesSachkunde: shift.participants[0]?.maxPlacesSachkunde
            }))
        };

        try {
            const response = await fetch(`https://85.215.137.109/Records/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedRecord),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Fehlerdetails:', errorData);
                setPopupMessage('Aktualisierung des Dienstes fehlgeschlagen!');
                setShowPopup(true);
                throw new Error('Aktualisierung des Dienstes fehlgeschlagen!');
            }

            navigate('/home/admin');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        if (popupMessage.includes('Sitzung ist abgelaufen')) {
            navigate('/login');
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/home')}>
                <FaArrowLeft size={24} color="black" />
            </div>

            {error && (
                <div style={{ color: 'red', margin: '20px 0' }}>
                    {error}
                    {error.includes('Sitzung') && (
                        <button
                            style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginTop: '10px'
                            }}
                            onClick={() => navigate('/login')}
                        >
                            Zum Login
                        </button>
                    )}
                </div>
            )}
            <h1>Dienst bearbeiten</h1>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                    <label>Einsatzleitung:</label>
                    <input
                        type="text"
                        value={einsatzleitung}
                        onChange={(e) => setEinsatzleitung(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Disponent:</label>
                    <input
                        type="text"
                        value={disponent}
                        onChange={(e) => setDisponent(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Dienstname:</label>
                    <input
                        type="text"
                        value={dienstname}
                        onChange={(e) => setDienstname(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Beschreibung:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Datum:</label>
                    <input
                        type="date"
                        value={datum}
                        onChange={(e) => setDatum(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Stadt:</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Geben Sie die Stadt ein"
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Adresse:</label>
                    <input
                        type="text"
                        value={adresse}
                        onChange={(e) => setAdresse(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label>Dienstzeiten:</label>
                    {shifts.map((shift, index) => (
                        <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Startzeit:</label>
                                    <input
                                        type="time"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.startZeit}
                                        onChange={(e) => handleShiftChange(index, 'startZeit', e.target.value)}
                                        required
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <label>Endzeit:</label>
                                    <input
                                        type="time"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.endZeit}
                                        onChange={(e) => handleShiftChange(index, 'endZeit', e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <label>Dienstkleidung:</label>
                                <select
                                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                    value={shift.dienstkleidung}
                                    onChange={(e) => handleShiftChange(index, 'dienstkleidung', e.target.value)}
                                    required
                                >
                                    <option value="Anzug">Anzug</option>
                                    <option value="Cargo">Cargo</option>
                                </select>
                            </div>

                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Max Plätze Service:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.participants[0]?.maxPlacesService}
                                        onChange={(e) => handleShiftChange(index, 'maxPlacesService', e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Max Plätze 34a:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.participants[0]?.maxPlaces34a}
                                        onChange={(e) => handleShiftChange(index, 'maxPlaces34a', e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <label>Max Plätze Sachkunde:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.participants[0]?.maxPlacesSachkunde}
                                        onChange={(e) => handleShiftChange(index, 'maxPlacesSachkunde', e.target.value)}
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                style={{
                                    backgroundColor: '#dc3545',
                                    color: 'white',
                                    border: 'none',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    width: '100%',
                                }}
                                onClick={() => handleRemoveShift(index)}
                            >
                                Entfernen
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        style={{
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            padding: '10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            width: '100%',
                        }}
                        onClick={handleAddShift}
                    >
                        + Dienstzeit hinzufügen
                    </button>
                </div>

                <button
                    type="submit"
                    style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                    }}
                    disabled={loading}
                >
                    {loading ? 'Speichern...' : 'Dienst aktualisieren'}
                </button>
            </form>
            <button
                style={{
                    backgroundColor: '#6c757d',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '20px',
                    width: '100%',
                }}
                onClick={() => navigate('/home/admin')}
            >
                Zurück zur Admin-Seite
            </button>

            {showPopup && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        maxWidth: '400px',
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        <h2>Information</h2>
                        <p>{popupMessage}</p>
                        <button
                            style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginTop: '10px',
                                width: '100%',
                            }}
                            onClick={handleClosePopup}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditRecordManager;
