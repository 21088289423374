import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const Forms = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFiles = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://85.215.137.109/Files/list', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                setFiles(response.data);
                setLoading(false);
            } catch (err) {
                setError('Fehler beim Laden der Dateien');
                setLoading(false);
            }
        };

        fetchFiles();
    }, [navigate]);

    const handleDownload = (fileName) => {
        const token = localStorage.getItem('authToken');
        const url = `https://85.215.137.109/Files/download/${fileName}`;

        if (token) {
            window.location.href = `${url}?authToken=${token}`;
        } else {
            setError('Sie m�ssen sich erneut anmelden.');
        }
    };

    if (loading) return <p>Lade Dateien...</p>;

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    {error.includes('Sitzung') && (
                        <button className="btn btn-primary mt-2" onClick={() => navigate('/login')}>
                            Zum Login
                        </button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div>
            <h1>Formulare zum Download</h1>
            <ul className="list-group">
                {files.map((fileName, index) => (
                    <li key={index} className="list-group-item">
                        <button className="btn btn-primary" onClick={() => handleDownload(fileName)}>
                            {fileName}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Forms;
