import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './AddUser.css';
import { FaArrowLeft } from 'react-icons/fa'; // Importiere das Icon
import axios from 'axios';

const EditUser = () => {
    const navigate = useNavigate();
    const { userId } = useParams(); // Hole die Benutzer-ID aus den URL-Parametern

    const [formData, setFormData] = useState({
        email: '',
        isAdmin: false,
        firstName: '',
        lastName: '',
        quali: '',
        bewacherId: '',
        ePin: ''
    });

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true); // Zustand f�r das initiale Laden der Daten

    // Lade die Benutzerdaten bei der Komponentenerstellung
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`https://85.215.137.109/Users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                // Setze die empfangenen Benutzerdaten in das Formular
                const userData = response.data;
                setFormData({
                    email: userData.email || '',
                    isAdmin: userData.isAdmin,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    quali: userData.quali || '',
                    bewacherId: userData.bewacherId || '',
                    ePin: userData.ePin || '',
                });
            } catch (error) {
                setError('Fehler beim Laden der Benutzerdaten.');
            } finally {
                setInitialLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem('authToken');

            // Sende die aktualisierten Benutzerdaten an die API
            const response = await axios.put(
                `https://85.215.137.109/Users/UpdateUser/${userId}`, // API-Endpunkt zum Aktualisieren des Benutzers
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                navigate('/home/showusers'); // Zur Benutzerliste weiterleiten nach dem Aktualisieren
            }
        } catch (error) {
            setError('Fehler beim Aktualisieren des Benutzers.');
        } finally {
            setLoading(false);
        }
    };

    if (initialLoading) {
        return <div className="loading">Lade Benutzerdaten...</div>;
    }

    return (
        <div className="edit-user-container">
            {/* Pfeil, der zur Benutzerliste zur�ckf�hrt */}
            <button className="back-button" onClick={() => navigate('/home/showusers')}>
                <FaArrowLeft /> Zur�ck zur Benutzerliste
            </button>

            <h2>Benutzer bearbeiten</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="edit-user-form">
                <div className="form-group">
                    <label htmlFor="firstName">Vorname:</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Nachname:</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">E-Mail (Optional):</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Standard: default@example.com"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="quali">Qualifikation:</label>
                    <select
                        id="quali"
                        name="quali"
                        value={formData.quali}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Bitte w�hlen</option>
                        <option value="Service">Service</option>
                        <option value="34a">34a</option>
                        <option value="Sachkunde">Sachkunde</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="bewacherId">BewacherID (Optional):</label>
                    <input
                        type="text"
                        id="bewacherId"
                        name="bewacherId"
                        value={formData.bewacherId}
                        onChange={handleChange}
                        placeholder="Standard: Nicht verf�gbar"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="ePin">E-PIN (Optional):</label>
                    <input
                        type="text"
                        id="ePin"
                        name="ePin"
                        value={formData.ePin}
                        onChange={handleChange}
                        placeholder="Standard: Nicht verf�gbar"
                    />
                </div>
                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            name="isAdmin"
                            checked={formData.isAdmin}
                            onChange={handleChange}
                        />
                        Admin-Benutzer
                    </label>
                </div>
                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Wird aktualisiert...' : 'Benutzer aktualisieren'}
                </button>
            </form>
        </div>
    );
};

export default EditUser;
