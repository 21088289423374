import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Forms from './pages/Forms';  // Umbenannte Komponente
import Dienste from './pages/Dienste';  // Beispiel-Komponente
import Login from './pages/Login';  // Beispiel-Komponente
import ConfirmationPage from './pages/Confirmation';
import Admin from './pages/Admin';
import AdminServiceRequests from './pages/AdminServiceRequests';
import MitarbeiterBereich from './pages/Mitarbeiterbereich';
import RecordManager from './pages/RecordManager';
import UserList from './pages/UserList';
import EditRecordManager from './pages/EditRecord';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';





function App() {

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Dashboard />}>
                <Route index element={<Dienste />} /> {/* Hier Forms statt Home */}
                <Route path="forms" element={<Forms />} />
                <Route path="dienste" element={<Dienste />} />
                <Route path="mapage" element={<MitarbeiterBereich />} />
                <Route path="admin" element={<Admin />} />
                <Route path="showusers" element={<UserList />} />
            </Route>
            <Route path="/confirmation/:id" element={<ConfirmationPage />} />
            <Route path="/admin-service-requests" element={<AdminServiceRequests />} />
            <Route path="/recordmanager" element={<RecordManager />} />
            <Route path="/editrecord/:id" element={<EditRecordManager />} />
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/edit-user/:userId" element={<EditUser />} />


        </Routes>
    );
}

export default App;
