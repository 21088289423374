import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios'; // Importiere axios für API-Anfragen

const Dashboard = () => {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [newRequests, setNewRequests] = useState(false);

    useEffect(() => {
        const adminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(adminStatus);

        const checkNewRequests = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get('https://85.215.137.109/ServiceRequests/Admin/ServiceRequests', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.data && response.data.length > 0) {
                    setNewRequests(true);
                } else {
                    setNewRequests(false);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Service-Requests:', error);
                setNewRequests(false); // Setze auf false, wenn die Anfrage fehlschlägt
            }
        };

        if (adminStatus) {
            checkNewRequests();
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('isAdmin');
        navigate('/login');
    };

    const handleNotificationClick = () => {
        navigate('/admin-service-requests');
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/home">
                        <img
                            src="/pics/powerLogo.png"
                            alt="Logo"
                            style={{ height: '40px' }}
                        />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 flex-column flex-lg-row gap-2">
                            <li className="nav-item">
                                <Link className="nav-link" to="forms">
                                    Formulare
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="dienste">
                                    Dienste
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="mapage">
                                    MeinBereich
                                </Link>
                            </li>
                            {isAdmin && (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="admin">
                                            Verwaltung
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="showusers">
                                            Benutzer
                                        </Link>
                                    </li>
                                    {newRequests && (
                                        <li className="nav-item">
                                            <span
                                                className="nav-link"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleNotificationClick}
                                            >
                                                {/* Glocke für Mobilgeräte und Desktop angepasst */}
                                                <FaBell size={24} className="d-block d-lg-none mx-auto" color="red" />
                                                <FaBell size={24} className="d-none d-lg-block" color="red" />
                                            </span>
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <span
                                    className="nav-link"
                                    onClick={handleLogout}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Logout
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="main-content p-4">
                <Outlet /> {/* Hier wird der Inhalt der Unterseiten gerendert */}
            </div>
        </div>
    );
};

export default Dashboard;
