import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaEllipsisV, FaDownload } from 'react-icons/fa'; // Importieren Sie das Download-Icon
import './Admin.css';
import axios from 'axios';

const Admin = () => {
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [expandedServiceTime, setExpandedServiceTime] = useState(null);
    const [selectedCity, setSelectedCity] = useState('');
    const [cities, setCities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeOptionsMenu, setActiveOptionsMenu] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(null);
    const [showExportModal, setShowExportModal] = useState(false);
    const [availableColumns, setAvailableColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [currentRecordname, setCurrentRecordname] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch('https://85.215.137.109/Records/Admin/Records', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Anfrage fehlgeschlagen!');
                }

                const data = await response.json();
                setRecords(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchCities = async () => {
            const token = localStorage.getItem('authToken');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            if (!token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
                setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch('https://85.215.137.109/Records/Cities', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Städte konnten nicht abgerufen werden.');
                }

                const citiesData = await response.json();
                setCities(citiesData);
                if (citiesData.length > 0) {
                    setSelectedCity(citiesData[0]);
                }
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();
        fetchCities();
    }, []);

    useEffect(() => {
        const filtered = records.filter(record => record.ort === selectedCity);
        setFilteredRecords(filtered);
    }, [records, selectedCity]);

    useEffect(() => {
        const fetchColumns = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(
                    'https://85.215.137.109/Users/user-columns',
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setAvailableColumns(response.data);

            } catch (error) {
                console.error('Fehler beim Abrufen der Spaltennamen', error);
            }
        };

        fetchColumns();
    }, []);

    const handleRecordManagerClick = () => {
        navigate('/recordmanager');
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const toggleOptionsMenu = (id) => {
        setActiveOptionsMenu(activeOptionsMenu === id ? null : id);
    };

    const handleDeleteRecord = async (id) => {
        const confirmDelete = window.confirm('Sind Sie sicher, dass Sie diesen Dienst löschen möchten?');

        if (confirmDelete) {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(`https://85.215.137.109/Records/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Löschen fehlgeschlagen!');
                }

                setRecords(records.filter(record => record.id !== id));
                setActiveOptionsMenu(null);
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const handleEditRecord = (id) => {
        navigate(`/editrecord/${id}`);
    };

    const handleParticipantClick = (userParticipant, recordName) => {
        if (window.confirm(`Wollen Sie ${userParticipant.firstName} ${userParticipant.lastName} wirklich von "${recordName}" entfernen?`)) {
            handleRemoveParticipant(userParticipant);
        }
    };

    const handleRemoveParticipant = async (userParticipant) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.post(
                `https://85.215.137.109/Users/removeParticipant/${userParticipant.id}`, // ID wird in die URL eingefügt
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status !== 200) {
                throw new Error('Fehler beim Entfernen des Teilnehmers.');
            }

            console.log(`Teilnehmer ${userParticipant.firstName} ${userParticipant.lastName} wurde erfolgreich entfernt.`);
        } catch (error) {
            console.error('Fehler:', error.message);
        }
    };

    const handleServiceTimeClick = (serviceTimeId) => {
        setExpandedServiceTime(expandedServiceTime === serviceTimeId ? null : serviceTimeId);
        setSearchQuery('');  // Setzt den Suchbegriff zurück, wenn eine neue Liste expandiert wird
    };

    const handleExportClick = (recordId, recordName) => {
        setCurrentRecordId(recordId);
        setCurrentRecordname(recordName);
        setShowExportModal(true);
    };

    const handleCheckboxChange = (column) => {
        setSelectedColumns(prev =>
            prev.includes(column)
                ? prev.filter(c => c !== column)
                : [...prev, column]
        );
    };

    const handleExport = async () => {
        if (selectedColumns.length === 0) {
            alert('Bitte wählen Sie mindestens eine Spalte aus.');
            return;
        }
        console.log(selectedColumns);
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.post(
                `https://85.215.137.109/Files/export/${currentRecordId}`,  // Verwenden Sie den Test-Endpunkt für die einfache Excel-Datei
                selectedColumns,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    responseType: 'blob',  // Sehr wichtig! Der Response-Typ muss 'blob' sein, um binäre Daten zu behandeln
                }
            );

            const contentDisposition = response.headers['content-disposition'];
            let fileName = `${currentRecordname}_Mitarbeiter_Export.xlsx`; // Fallback-Name, falls keiner gesetzt ist
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            setShowExportModal(false);
        } catch (error) {
            console.error('Fehler beim Exportieren der Daten', error);
        }
    };

    const filteredParticipants = (participants) => {
        if (!searchQuery) return participants;
        return participants.filter(participant =>
            participant.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            participant.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    {error}
                    {error.includes('Sitzung') && (
                        <button className="btn btn-primary mt-2" onClick={() => navigate('/login')}>Zum Login</button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="admin-container">
            <header className="admin-header">
                <h1>Dienstverwaltung</h1>

                <div className="add-record-icon" onClick={handleRecordManagerClick}>
                    <FaPlus size={38} color="green" />
                </div>
            </header>
            <div className="city-selector">
                <label>Stadt wählen:</label>
                <select value={selectedCity} onChange={handleCityChange}>
                    {cities.map(city => (
                        <option key={city} value={city}>
                            {city}
                        </option>
                    ))}
                </select>
            </div>
            <div className="records-container">
                {filteredRecords.length === 0 ? (
                    <p>Keine Dienste für die ausgewählte Stadt verfügbar.</p>
                ) : (
                    filteredRecords.map((record) => (
                        <div key={record.id} className="record-item">
                            <div className="record-details">
                                <p><strong>Dienst:</strong> {record.dienstname}</p>
                                <p><strong>Einsatzleitung:</strong> {record.einsatzleitung}</p>
                                <p><strong>Beschreibung:</strong> {record.description}</p>
                                <p><strong>Disponent:</strong> {record.disponent}</p>
                                <p><strong>Datum:</strong> {new Date(record.datum).toLocaleDateString('de-DE')}</p>

                                {record.serviceTimes.map((serviceTime) => (
                                    <div key={serviceTime.id} className="service-time-summary">
                                        <p onClick={() => handleServiceTimeClick(serviceTime.id)} className="service-time-toggle">
                                            <strong>{serviceTime.startZeit} bis {serviceTime.endZeit}</strong>
                                            <span className="toggle-icon">{expandedServiceTime === serviceTime.id ? '▲' : '▼'}</span>
                                        </p>
                                        {expandedServiceTime === serviceTime.id && (
                                            <div className="service-time-details">
                                                <div className="search-bar">
                                                    <label>Mitarbeiter suchen:</label>
                                                    <input
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={handleSearchQueryChange}
                                                        placeholder="Vorname oder Nachname"
                                                    />
                                                </div>
                                                <p><strong>Kleidung:</strong> {serviceTime.dienstkleidung}</p>
                                                <p><strong>Service Position:</strong> {serviceTime.participants?.[0]?.takenService} von {serviceTime.participants?.[0]?.maxPlacesService}</p>
                                                <p><strong>§34a Position:</strong> {serviceTime.participants?.[0]?.taken34a} von {serviceTime.participants?.[0]?.maxPlaces34a}</p>
                                                <p><strong>Sachkunde Position:</strong> {serviceTime.participants?.[0]?.takenSachkunde} von {serviceTime.participants?.[0]?.maxPlacesSachkunde}</p>

                                                <p><strong>Teilnehmer:</strong></p>
                                                <ul>
                                                    {filteredParticipants(serviceTime.participants?.[0]?.userParticipants).map((participant, idx) => (
                                                        <li
                                                            key={idx}
                                                            onClick={() => handleParticipantClick(participant, record.dienstname)}
                                                        >
                                                            {participant.firstName} {participant.lastName} - {participant.email} ({participant.quali})
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="options-menu">
                                <FaDownload onClick={() => handleExportClick(record.id, record.dienstname)} className="export-icon" /> {/* Export-Symbol */}
                                <FaEllipsisV onClick={() => toggleOptionsMenu(record.id)} />
                                {activeOptionsMenu === record.id && (
                                    <div className="dropdown-menu">
                                        <button onClick={() => handleEditRecord(record.id)}>Dienst ändern</button>
                                        <button onClick={() => handleDeleteRecord(record.id)}>Dienst löschen</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
            {confirmPopup && (
                <div className="confirm-popup">
                    <p>
                        Wollen Sie {confirmPopup.userParticipant.firstName} {confirmPopup.userParticipant.lastName} wirklich von "{confirmPopup.recordName}" entfernen?
                    </p>
                    <button onClick={handleRemoveParticipant}>Ja, entfernen</button>
                    <button onClick={() => setConfirmPopup(null)}>Abbrechen</button>
                </div>
            )}

            {/* Export-Modal */}
            {showExportModal && (
                <div className="export-modal">
                    <h3>Spalten auswählen</h3>
                    <div className="columns-list">
                        {availableColumns.map((column, idx) => (
                            <div key={idx}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={column}
                                        onChange={() => handleCheckboxChange(column)}
                                        checked={selectedColumns.includes(column)}
                                    />
                                    {column}
                                </label>
                            </div>
                        ))}
                    </div>
                    <button onClick={handleExport}>Exportieren</button>
                    <button onClick={() => setShowExportModal(false)}>Abbrechen</button>
                </div>
            )}
        </div>
    );
};

export default Admin;
