import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Importiere das Pfeil-Icon

const RecordManager = () => {
    const [einsatzleitung, setEinsatzleitung] = useState('');
    const [disponent, setDisponent] = useState('');
    const [dienstname, setDienstname] = useState('');
    const [description, setDescription] = useState('');
    const [datum, setDatum] = useState('');
    const [city, setCity] = useState('');
    const [adresse, setAdresse] = useState('');
    const [shifts, setShifts] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('authToken');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        if (!userId || !token || !tokenExpiration || new Date(tokenExpiration) <= new Date()) {
            setError('Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.');
            setLoading(false);
            return;
        }
    }, []);

    const handleAddShift = () => {
        setShifts([...shifts, { zeitStart: '', zeitEnd: '', dienstkleidung: 'Anzug', maxPlacesService: 0, maxPlaces34a: 0, maxPlacesSachkunde: 0 }]);
    };

    const handleRemoveShift = (index) => {
        const updatedShifts = shifts.filter((_, i) => i !== index);
        setShifts(updatedShifts);
    };

    const handleShiftChange = (index, field, value) => {
        const updatedShifts = shifts.map((shift, i) =>
            i === index ? { ...shift, [field]: value } : shift
        );
        setShifts(updatedShifts);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const token = localStorage.getItem('authToken');

        const newRecord = {
            einsatzleitung,
            disponent,
            description,
            datum,
            ort: city,
            dienstname: dienstname,
            adresse,
            shifts,
        };

        try {
            const response = await fetch('https://85.215.137.109/Records', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newRecord),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Fehlerdetails:', errorData);
                throw new Error('Erstellung des Dienstes fehlgeschlagen!');
            }

            navigate('/home/admin');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            {/* Hier f�gen wir den Pfeil f�r die Navigation hinzu */}
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/home')}>
                <FaArrowLeft size={24} color="black" />
            </div>

            {error && (
                <div style={{ color: 'red', margin: '20px 0' }}>
                    {error}
                    {error.includes('Sitzung') && (
                        <button
                            style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginTop: '10px'
                            }}
                            onClick={() => navigate('/login')}
                        >
                            Zum Login
                        </button>
                    )}
                </div>
            )}
            <h1>Neuen Dienst erstellen</h1>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                    <label>Einsatzleitung:</label>
                    <input
                        type="text"
                        value={einsatzleitung}
                        onChange={(e) => setEinsatzleitung(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Disponent:</label>
                    <input
                        type="text"
                        value={disponent}
                        onChange={(e) => setDisponent(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Dienstname:</label>
                    <input
                        type="text"
                        value={dienstname}
                        onChange={(e) => setDienstname(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Beschreibung:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Datum:</label>
                    <input
                        type="date"
                        value={datum}
                        onChange={(e) => setDatum(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Stadt:</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Geben Sie die Stadt ein"
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Adresse:</label>
                    <input
                        type="text"
                        value={adresse}
                        onChange={(e) => setAdresse(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label>Dienstzeiten:</label>
                    {shifts.map((shift, index) => (
                        <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Startzeit:</label>
                                    <input
                                        type="time"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.zeitStart}
                                        onChange={(e) => handleShiftChange(index, 'zeitStart', e.target.value)}
                                        required
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <label>Endzeit:</label>
                                    <input
                                        type="time"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.zeitEnd}
                                        onChange={(e) => handleShiftChange(index, 'zeitEnd', e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <label>Dienstkleidung:</label>
                                <select
                                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                    value={shift.dienstkleidung}
                                    onChange={(e) => handleShiftChange(index, 'dienstkleidung', e.target.value)}
                                    required
                                >
                                    <option value="Anzug">Anzug</option>
                                    <option value="Cargo">Cargo</option>
                                </select>
                            </div>

                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Max Plätze Service:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.maxPlacesService}
                                        onChange={(e) => handleShiftChange(index, 'maxPlacesService', e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: '1', marginRight: '10px' }}>
                                    <label>Max Plätze 34a:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.maxPlaces34a}
                                        onChange={(e) => handleShiftChange(index, 'maxPlaces34a', e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: '1' }}>
                                    <label>Max Plätze Sachkunde:</label>
                                    <input
                                        type="number"
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={shift.maxPlacesSachkunde}
                                        onChange={(e) => handleShiftChange(index, 'maxPlacesSachkunde', e.target.value)}
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                style={{
                                    backgroundColor: '#dc3545',
                                    color: 'white',
                                    border: 'none',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    width: '100%',
                                }}
                                onClick={() => handleRemoveShift(index)}
                            >
                                Entfernen
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        style={{
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            padding: '10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            width: '100%',
                        }}
                        onClick={handleAddShift}
                    >
                        + Dienstzeit hinzufügen
                    </button>
                </div>

                <button
                    type="submit"
                    style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                    }}
                    disabled={loading}
                >
                    {loading ? 'Speichern...' : 'Dienst erstellen'}
                </button>
            </form>
            <button
                style={{
                    backgroundColor: '#6c757d',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '20px',
                    width: '100%',
                }}
                onClick={() => navigate('/home/admin')}
            >
                Zurück zur Admin-Seite
            </button>
        </div>
    );
};

export default RecordManager;
